import React, { PureComponent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const animation = {
  initial: { opacity: 0 },
  active: {
    opacity: 1,
    transition: {
      delay: 0.6,
      when: 'beforeChildren',
      staggerChildren: 1,
    },
  },
  exit: { opacity: 0 },
};

class Transition extends PureComponent {
  render() {
    let animationKey = 'customDiv';

    if (typeof window !== 'undefined') {
      animationKey = location.pathname;
    }

    return (
      <AnimatePresence>
        <motion.div
          key={animationKey}
          className="app app__content"
          variants={animation}
          initial="initial"
          animate="active"
          exit="exit">
          {this.props.children}
        </motion.div>
      </AnimatePresence>
    );
  }
}

// Page Transitions
const pageTransition = ({ element, props }: any) => {
  return <Transition {...props}>{element}</Transition>;
};

export default pageTransition;
