import pageTransition from './src/components/transition';

// add page transition
export const wrapPageElement = pageTransition;

// disable auto scroll to position
export const shouldUpdateScroll = () => {
  return false;
};

// load prismjs theme
// require('prismjs/themes/prism-tomorrow.css');
// require('prismjs/plugins/line-numbers/prism-line-numbers.css');
